import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Playground, store } from "graphql-playground-react";

ReactDOM.render(
  <Provider store={store}>
    <Playground 
    endpoint="https://4quxo2r5ufhttcxneaxkm3jh4e.appsync-api.eu-west-1.amazonaws.com/graphql"
    settings={{
      "request.globalHeaders": {
        "x-api-key": "da2-nxpsujk7pzfhpdkycabgkuk2e4"
      }
    }}
    />
  </Provider>,
  document.body
);
